import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "./AuthContext";
import { decodeToken, isExpired } from "react-jwt";
import {
  ClientProductProps,
  ProductProps,
  TokenProps,
} from "../types/interfaces";
import {
  ALERT_INITIAL_STATE,
  CLIENT_PRODUCT_INITIAL_STATE,
  TOKEN_INITIAL_STATE,
} from "../utils/data";
import { Base64 } from "js-base64";
import { useRequest } from "../hooks";

interface Props {
  children: JSX.Element | JSX.Element[];
}

interface ResponseProps extends Response {
  success: boolean;
}

const AuthProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const { handleRequest } = useRequest();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userData, setUserData] = useState<TokenProps>(TOKEN_INITIAL_STATE);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSuccess, setIsSuccess] = useState(ALERT_INITIAL_STATE);
  const [isError, setIsError] = useState(ALERT_INITIAL_STATE);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isModalRecoverOpen, setIsModalRecoverOpen] = useState(false);
  const [productList, setProductList] = useState<ClientProductProps[]>([]);
  const [productSelected, setProductSelected] = useState<ClientProductProps>(
    CLIENT_PRODUCT_INITIAL_STATE
  );
  const [isModalChangePasswordVisible, setIsModalChangePasswordVisible] =
    useState(false);
  const [isModalResetPasswordVisible, setIsModalResetPasswordVisible] =
    useState(false);

  useEffect(() => {
    validateSession();
    resetCredentials();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsSuccess(ALERT_INITIAL_STATE);
      setIsError(ALERT_INITIAL_STATE);
    }, 3000);
  }, [isSuccess, isError]);

  useEffect(() => {
    const tokenCoded = localStorage.getItem("info");
    if (tokenCoded) {
      const dataUser: TokenProps | null = decodeToken(tokenCoded);
      if (dataUser) {
        setUserData(dataUser);
        setIsAdmin(dataUser?.role?.adm);
        setProductList(dataUser?.client?.details);
        if (dataUser?.client?.details?.length > 0)
          setProductSelected(dataUser.client.details[0]);
      }
    }
  }, [localStorage.getItem("info")]);

  const logoutHandler = async () => {
    setIsLoading(true);
    localStorage.clear();
    setIsLoggedIn(false);
    navigate("/");
    setIsLoading(false);
  };

  const resetCredentials = () => {
    setUsername("");
    setPassword("");
  };

  const loginHandler = async () => {
    setIsLoading(true);
    try {
      const loop1Pass = Base64.encode(password);
      const loop2Pass = Base64.encode(loop1Pass);
      const data = {
        username,
        password: loop2Pass,
      };
      let options: RequestInit = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      };
      const res = await fetch(`${process.env.REACT_APP_DEV_API}/auth`, options);
      const response = await res.json();
      if (response.success) {
        localStorage.setItem("info", response.token);
        const decodedToken: TokenProps | null = decodeToken(response.token);
        if (decodedToken) {
          navigate(`/support`);
        } else {
          resetCredentials();
          setIsLoading(false);
          setIsError({ status: true, message: "Not Authorized" });
        }
      } else {
        setIsLoading(false);
        setIsError({ status: true, message: "Not Authorized" });
      }
    } catch (e) {
      setIsLoading(false);
      setIsError({ status: true, message: "Not Authorized" });
    }
  };

  const validateSession = () => {
    if (validateToken()) {
      setIsLoggedIn(true);
      setIsLoading(false);
    } else {
      logoutHandler();
    }
  };

  const validateToken = (): boolean => {
    const token = localStorage.getItem("info");
    if (token) {
      const expired = isExpired(token);
      if (expired) return false;
      return true;
    } else {
      return false;
    }
  };

  const recoverClient = async (email: string) => {
    setIsLoading(true);
    try {
      let options: RequestInit = {
        method: "POST",
        body: JSON.stringify({ email }),
      };
      const res = (await fetch(
        `${process.env.REACT_APP_DEV_API}/auth/recover`,
        {
          ...options,
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )) as ResponseProps;
      const response = await res.json();
      if (response.success) {
        setIsLoading(false);
        setIsSuccess({ status: true, message: "All good!" });
        setIsModalRecoverOpen(false);
      } else {
        setIsLoading(false);
        setIsError({ status: true, message: "Please try again" });
        setIsModalRecoverOpen(false);
      }
    } catch (e) {
      setIsLoading(false);
      setIsError({ status: true, message: "Please try again" });
      setIsModalRecoverOpen(false);
    }
  };

  const handleChangeProduct = (idProduct: string) => {
    const foundProduct = productList.find(
      (product) => product.product.id === idProduct
    );
    if (foundProduct) setProductSelected(foundProduct);
  };

  const validateExternalSession = (token: string, session: string | null) => {
    setIsLoading(true);
    try {
      handleRequest({
        endpoint: `auth/session/?id=${session}&authToken=${token}`,
        onSuccess: (response) => {
          if (response.success) {
            localStorage.setItem("info", response.data.token);
            const decodedToken: TokenProps | null = decodeToken(
              response.data.token
            );
            if (decodedToken) {
              navigate(`/support`);
              setIsSuccess({ status: true, message: "Welcome!" });
            } else {
              setIsLoading(false);
              setIsError({ status: true, message: "Not Authorized" });
            }
          } else {
            setIsLoading(false);
            setIsError({ status: true, message: "Not Authorized" });
          }
        },
        onError: (e) => {
          setIsLoading(false);
          setIsError({ status: true, message: "Not Authorized" });
          logoutHandler();
        },
      });
    } catch (e) {
      setIsLoading(false);
      setIsError({ status: true, message: "Not Authorized" });
      logoutHandler();
    }
  };

  const handleChangePassword = ({
    data,
    next,
  }: {
    data: {
      currentPassword: string;
      newPassword: string;
    };
    next: () => void;
  }) => {
    try {
      if (userData.id) {
        setIsLoading(true);
        let options: RequestInit = {
          method: "PUT",
          body: JSON.stringify(data),
        };
        handleRequest({
          endpoint: `user/password/${userData.id}`,
          options,
          onSuccess: (response) => {
            setIsLoading(false);
            if (response.success) {
              setIsSuccess({ status: true, message: "Password changed" });
              next();
            } else {
              setIsError({ status: true, message: "Please try again" });
            }
          },
          onError: (e) => {
            setIsLoading(false);
            setIsError({ status: true, message: "Please try again" });
          },
        });
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      setIsError({ status: true, message: "Not Authorized" });
    }
  };

  const handleSendResetPassword = ({
    username,
    next,
  }: {
    username: string;
    next: () => void;
  }) => {
    try {
      setIsLoading(true);
      let options: RequestInit = {
        method: "GET",
        headers: {
          ["x-origin"]: "login.quattroapps.app",
        },
      };
      handleRequest({
        endpoint: `auth/recover/${username}`,
        options,
        onSuccess: (response) => {
          setIsLoading(false);
          if (response) {
            setIsSuccess({ status: true, message: "Password Sent" });
            next();
          }
        },
        onError: (e) => {
          setIsLoading(false);
          setIsError({ status: true, message: "Please try Again" });
        },
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setIsError({ status: true, message: "Not Authorized" });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        username,
        password,
        isLoggedIn,
        isSuccess,
        isError,
        isLoading,
        isAdmin,
        userData,
        isModalRecoverOpen,
        productList,
        productSelected,
        isModalChangePasswordVisible,
        isModalResetPasswordVisible,
        openCloseModalPassword: (value) =>
          setIsModalChangePasswordVisible(value),
        onOpenCloseModalRecover: (value: boolean) =>
          setIsModalRecoverOpen(value),
        openCloseModalResetPassword: (value) =>
          setIsModalResetPasswordVisible(value),
        handleSendResetPassword,
        handleChangePassword,
        onChangeUsername: (value: string) => setUsername(value),
        onChangePassword: (value: string) => setPassword(value),
        handleLogin: loginHandler,
        onLogout: logoutHandler,
        validateToken,
        recoverClient,
        handleChangeProduct,
        validateExternalSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
